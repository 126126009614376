import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.concat.js";

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// const graffiti = () => import( "./graffiti");
var commentBox = function commentBox() {
  return import("./commentBox");
};

var proPage = function proPage() {
  return import("../common/proPage");
};

export default {
  components: {
    // graffiti,
    commentBox: commentBox,
    proPage: proPage
  },
  props: {
    source: {
      type: Number
    },
    type: {
      type: String
    },
    userId: {
      type: Number
    }
  },
  data: function data() {
    return {
      isGraffiti: false,
      total: 0,
      replyDialogVisible: false,
      floorComment: {},
      replyComment: {},
      comments: [],
      pagination: {
        current: 1,
        size: 10,
        total: 0,
        source: this.source,
        commentType: this.type,
        floorCommentId: null
      }
    };
  },
  computed: {},
  created: function created() {
    this.getComments(this.pagination);
    this.getTotal();
  },
  methods: {
    toPage: function toPage(page) {
      this.pagination.current = page;
      window.scrollTo({
        top: document.getElementById('comment-content').offsetTop
      });
      this.getComments(this.pagination);
    },
    getTotal: function getTotal() {
      var _this = this;

      this.$http.get(this.$constant.baseURL + "/comment/getCommentCount", {
        source: this.source,
        type: this.type
      }).then(function (res) {
        if (!_this.$common.isEmpty(res.data)) {
          _this.total = res.data;
        }
      }).catch(function (error) {
        _this.$message({
          message: error.message,
          type: "error"
        });
      });
    },
    toChildPage: function toChildPage(floorComment) {
      floorComment.childComments.current += 1;
      var pagination = {
        current: floorComment.childComments.current,
        size: 5,
        total: 0,
        source: this.source,
        commentType: this.type,
        floorCommentId: floorComment.id
      };
      this.getComments(pagination, floorComment, true);
    },
    emoji: function emoji(comments, flag) {
      var _this2 = this;

      comments.forEach(function (c) {
        c.commentContent = c.commentContent.replace(/\n/g, '<br/>');
        c.commentContent = _this2.$common.faceReg(c.commentContent);
        c.commentContent = _this2.$common.pictureReg(c.commentContent);

        if (flag) {
          if (!_this2.$common.isEmpty(c.childComments) && !_this2.$common.isEmpty(c.childComments.records)) {
            c.childComments.records.forEach(function (cc) {
              c.commentContent = c.commentContent.replace(/\n/g, '<br/>');
              cc.commentContent = _this2.$common.faceReg(cc.commentContent);
              cc.commentContent = _this2.$common.pictureReg(cc.commentContent);
            });
          }
        }
      });
    },
    getComments: function getComments(pagination) {
      var _this3 = this;

      var floorComment = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var isToPage = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      this.$http.post(this.$constant.baseURL + "/comment/listComment", pagination).then(function (res) {
        if (!_this3.$common.isEmpty(res.data) && !_this3.$common.isEmpty(res.data.records)) {
          if (_this3.$common.isEmpty(floorComment)) {
            _this3.comments = res.data.records;
            pagination.total = res.data.total;

            _this3.emoji(_this3.comments, true);
          } else {
            if (isToPage === false) {
              floorComment.childComments = res.data;
            } else {
              floorComment.childComments.total = res.data.total;
              floorComment.childComments.records = floorComment.childComments.records.concat(res.data.records);
            }

            _this3.emoji(floorComment.childComments.records, false);
          }
        }
      }).catch(function (error) {
        _this3.$message({
          message: error.message,
          type: "error"
        });
      });
    },
    addGraffitiComment: function addGraffitiComment(graffitiComment) {
      this.submitComment(graffitiComment);
    },
    submitComment: function submitComment(commentContent) {
      var _this4 = this;

      var comment = {
        source: this.source,
        type: this.type,
        commentContent: commentContent
      };
      this.$http.post(this.$constant.baseURL + "/comment/saveComment", comment).then(function (res) {
        _this4.$message({
          type: 'success',
          message: '保存成功！'
        });

        _this4.pagination = {
          current: 1,
          size: 10,
          total: 0,
          source: _this4.source,
          commentType: _this4.type,
          floorCommentId: null
        };

        _this4.getComments(_this4.pagination);

        _this4.getTotal();
      }).catch(function (error) {
        _this4.$message({
          message: error.message,
          type: "error"
        });
      });
    },
    submitReply: function submitReply(commentContent) {
      var _this5 = this;

      var comment = {
        source: this.source,
        type: this.type,
        floorCommentId: this.floorComment.id,
        commentContent: commentContent,
        parentCommentId: this.replyComment.id,
        parentUserId: this.replyComment.userId
      };
      var floorComment = this.floorComment;
      this.$http.post(this.$constant.baseURL + "/comment/saveComment", comment).then(function (res) {
        var pagination = {
          current: 1,
          size: 5,
          total: 0,
          source: _this5.source,
          commentType: _this5.type,
          floorCommentId: floorComment.id
        };

        _this5.getComments(pagination, floorComment);

        _this5.getTotal();
      }).catch(function (error) {
        _this5.$message({
          message: error.message,
          type: "error"
        });
      });
      this.handleClose();
    },
    replyDialog: function replyDialog(comment, floorComment) {
      this.replyComment = comment;
      this.floorComment = floorComment;
      this.replyDialogVisible = true;
    },
    handleClose: function handleClose() {
      this.replyDialogVisible = false;
      this.floorComment = {};
      this.replyComment = {};
    }
  }
};